<script>
/*
CrudBase.methods.conclude.call(this); // super()

beforeLoad(form)=>{return form;}  Sobrescreva essa função para pré processar os dados antes de carregar os dados
beforeSave(form)=>{return form;}  Sobrescreva essa função para pré processar os dados antes de salvar
afterLoad()=>{}  Sobrescreva essa função para executar algo depois de carregar os dados
afterSave()=>{}  Sobrescreva essa função para executar algo depois de salvar
resource     Nome da tabela
confirmClose Confirmação para fechar
persistOnSaveData Se deve manter na mesma tela depois de salvar
 */


import FcmColorPick from '@components/diversos/FcmColorPick';
import { authComputed } from '@store/helpers';
import {firestore, FieldValue} from '@src/db';
import * as Workspace from "@common/Workspace";

export default {
  name: 'CrudEditBase',
  components: {FcmColorPick},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default: ()=>null,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    persistOnSave: {
      type: Boolean,
      default: false,
    },
  },
  data(vm) {
    return {
      resource: 'testBase',
      persistOnSaveData: false,
      confirmClose: false,

      hasError: null,
      tryingToUpdate: false,
      uploadProgress: 0,
      tryingToUpload: false,
      rules: {
        ...vm.$validateRules(),
      },

      valueId: null,
      isNew: false,
      valid: true,
      /**
       * form
       * @description Não é necessário inicializar, é inicializado automaticamente com o defaultForm no evento created
       */
      form: {},
      /**
       * defaultForm
       * @description Valores default para o formulário
       */
      defaultForm: {},
      /**
       * newForm
       * @description Valores default para novos formulários, não precisa repetir o que tem no defaultForm, apenas o que for diferente
       */
      newForm: {},
      isLoading: true,
      original: null,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    async value(value, oldValue)  {
      if(value && value.id===this.valueId || (!value && !this.valueId)) {
        //  console.warn('New id === old value', value, oldValue);
        return;
      }
      await this.loadForm();
    }
  },
  created: function () {
    this.form = _.cloneDeep(this.defaultForm);
   // this.newForm = _.cloneDeep({...this.defaultForm, ...this.newForm});
    this.newForm = _.defaultsDeep(this.newForm, _.cloneDeep(this.defaultForm));
  },
  mounted: async function() {
    await this.loadForm();
  },
  beforeDestroy: function() {
  },
  methods: {
    async close(form, confirm=false) {
      if(confirm && this.confirmClose && ((this.form && this.original && !_.isEqual(this.form, this.original))|| this.isNew)) {
        if(!await this.$showConfirm({title: this.$t('form.alert.hasChangesPending')})) {
          return;
        }
      }
      await this.init();
      this.$emit('input', null);
      this.$emit('closeDialog', form);
    },
    async init() {
      this.isLoading = true;
      this.form = await this.beforeInit(_.cloneDeep(this.newForm));
      this.isNew = true;
      this.valueId = null;
      await this.afterInit();
    },
    async loadForm(forceLoad=false) {
      await this.init();
      let form = _.cloneDeep({...this.newForm});

      if(this.value && this.value.id) {
        this.valueId = this.value.id;
        if(this.value.data && !forceLoad) {
          // form = { ..._.cloneDeep(this.defaultForm), ...this.value.data};
          form = _.defaultsDeep(_.cloneDeep(this.value.data), _.cloneDeep(this.defaultForm));
          this.isNew = false;
        }
        else {
          let obj = await firestore.collection(this.resource).doc(this.valueId).get();
          if(obj && obj.data()) {
           // form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
            form = _.defaultsDeep(obj.data(), _.cloneDeep(this.defaultForm));
            this.isNew = false;
          }
        }
      }
      if(this.value && this.value.isNew) {
        this.isNew = true;
        if(!this.value.id) {
          this.valueId = this.$newId();
          this.$emit('input', {...this.value, id: this.valueId});
        }
      }
      form = await this.beforeLoad(form);

      this.form = form;
      this.isLoading = false;
      await this.afterLoad();
      setTimeout(()=>{
        this.original = _.cloneDeep(form);
      }, 200);
      console.log('loadForm: '+this.valueId, this.form);
    },

    async conclude() {
      this.hasError = null;
      this.tryingToUpdate = true;

      let form = null;
      try {
        form = await this.beforeSave(_.cloneDeep(this.form));
      } catch(error) {
        console.error('Error on beforeSave', error);
        this.hasError = error;
        this.$showError({title:this.$t('form.error.update'), text: error.message || ''});
        this.tryingToUpdate = false;
        return false;
      }
      if(!form) {
        this.tryingToUpdate = false;
        return false;
      }

      try {
        form.meta = {...(form.meta || {}), ...(this.$metaInfo(this.isNew))};

        console.log('Save: '+this.valueId, form);
        const toSet = {...form, id: this.valueId};
        await firestore.collection(this.resource).doc(this.valueId).set(toSet,{merge: true});
        this.$emit('input', {...this.value, data: form});
        this.$emit('saved', {...this.value, data: form, valueId: this.valueId});
        this.$showSuccess();
        if(!this.persistOnSave && !this.persistOnSaveData) {
          await this.close(toSet);
        }
      } catch(error) {
        console.error('Error on save', error);
        this.hasError = error;
        this.$showError({title:this.$t('form.error.update'), text: error.message || ''});
        return false;
      }
      this.tryingToUpdate = false;
      await this.afterSave(form);
      return true;
    },


    async deleteItem() {
      if(!(await this.$showConfirm({title: this.$t('form.confirmDelete')}))) {
        return;
      }
      this.hasError = null;
      this.tryingToUpdate = true;

      let form = null;
      try {
        form = await this.beforeDelete(_.cloneDeep(this.form));
      } catch(error) {
        console.error('Error on beforeDelete', error);
        this.hasError = error;
        this.$showError({title:this.$t('form.error.update'), text: error.message || ''});
        this.tryingToUpdate = false;
        return false;
      }
      if(!form) {
        this.tryingToUpdate = false;
        return false;
      }
      try {
        form.active = false;
        form.meta = {...(form.meta || {}), ...(this.$metaInfo(this.isNew))};

        console.log('Delete: '+this.valueId, form);
        const toSet = {...form, id: this.valueId};
        await firestore.collection(this.resource).doc(this.valueId).set(toSet,{merge: true});
        this.$emit('input', {...this.value, data: form});
        this.$emit('deleted', {...this.value, data: form, valueId: this.valueId});
        this.$showSuccess();
        if(!this.persistOnSave && !this.persistOnSaveData) {
          await this.close(toSet);
        }
      } catch(error) {
        console.error('Error on save', error);
        this.hasError = error;
        this.$showError({title:this.$t('form.error.update'), text: error.message || ''});
        return false;
      }
      this.tryingToUpdate = false;
      await this.afterDelete();
      return true;
    },

    /**
     * @description Sobrescreva essa função para pré processar os dados antes da inicialização inicial
     * @param form
     * @returns {Promise<*>}
     */
    async beforeInit(form) {
      return form;
    },
    /**
     * @description Sobrescreva essa função para pré processar os dados antes de carregar os dados
     * @param form
     * @returns {Promise<*>}
     */
    async beforeLoad(form) {
      return form;
    },
    /**
     * @description Sobrescreva essa função para pré processar os dados antes de salvar
     * @param form
     * @returns {Promise<*>}
     */
    async beforeSave(form) {
      form.active = Boolean(form.active);
      return form;
    },


    /**
     * @description Sobrescreva essa função para pré processar os dados antes de deletar
     * @param form
     * @returns {Promise<*>}
     */
    async beforeDelete(form) {
      return form;
    },

    /**
     * @description Sobrescreva essa função para executar algo depois de inicializar o crud
     * @returns {Promise<void>}
     */
    async afterInit() {
    },
    /**
     * @description Sobrescreva essa função para executar algo depois de carregar os dados
     * @returns {Promise<void>}
     */
    async afterLoad() {
    },
    /**
     * @description Sobrescreva essa função para executar algo depois de salvar (Não é chamado em caso de erro)
     * @returns {Promise<void>}
     */
    async afterSave() {
    },
    /**
     * @description Sobrescreva essa função para executar algo depois de deletar (Não é chamado em caso de erro)
     * @returns {Promise<void>}
     */
    async afterDelete() {
    },
  },
}
</script>


<template>
  <div>
    <div v-show="isLoading" >
      <VProgressCircular indeterminate color="primary"></VProgressCircular>
    </div>
    <div v-if="!isLoading">
      <div>
        <VForm >
          <VContainer>
            <VRow>
              <VCol cols="12" sm="6" md="5">
                <VTextField v-model="form.name" :label="$t('form.name')"></VTextField>
              </VCol>
              <VCol cols="12" sm="4" md="2" class="d-flex align-center">
                <FcmColorPick
                    v-model="form.color"
                    :label="$t('form.color')"
                ></FcmColorPick>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" sm="4" md="3">
                <VSwitch
                    v-model="form.active"
                    :label="$t('form.active')"
                    :value="true"
                    hide-details
                ></VSwitch>
              </VCol>
            </VRow>
            <VRow v-if="hasError">
              <VCol cols="12" sm="12" md="12">
                <p>
                  {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
                </p>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" sm="12" md="12">
                <VProgressLinear v-if="tryingToUpload" :value="uploadProgress *100"></VProgressLinear>
              </VCol>
            </VRow>
            <VRow v-if="showActions" class="mt-4">
              <VCol cols="12" sm="12" md="12">
                <VBtn
                    :disabled="!valid"
                    color="success"
                    class="mr-4 mt-3"
                    @click="conclude"
                >
                  {{$t('form.conclude')}}
                </VBtn>

                <VBtn
                    color="warning"
                    class="mt-3"
                    @click="close(null, true)"
                >
                  {{$t('form.close')}}
                </VBtn>
                <VBtn
                    v-if="!isNew"
                    :disabled="tryingToUpdate"
                    color="error"
                    class="ml-4 mt-3"
                    @click="deleteItem()"
                >
                  {{$t('form.delete')}}
                </VBtn>
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
      </div>
    </div>
  </div>
</template>























