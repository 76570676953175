<script>
import CrudBase from '@components/cruds/CrudEditBase';
import FcmDate from "@components/diversos/FcmDate";
import {firestore, functions, Timestamp, FieldValue} from '@src/db';
import * as StringUtils from "@common/StringUtils";
import * as ObjectUtils from "@common/ObjectUtils";
import * as status from "@common/status";
import {authComputed} from "@store/helpers";
import firebase from 'firebase/app';

const ReportStatus = status.ReportStatus;

import {Chart, registerables} from 'chart.js';
Chart.register(...registerables);
import { Line as CLine } from 'vue-chartjs';

import {getDataGenres, getDataAges, getMarkers} from '@src/utils/charts';
import {encode} from 'js-base64';

export default {
  components: {FcmDate, CLine},
  extends: CrudBase,
  props: {
    profileEdit: {
      type: Boolean,
    },
    isSuperAdmin: {
      type: Boolean,
    },
    reportId: {
      type: String,
      default: '',
    }
  },
  data(vm) {
    return {
      resource: 'users',
      confirmClose: true,
      StringUtils: StringUtils,
      ReportStatus: ReportStatus,
      defaultForm: {
        active: false,
      },
      newForm: {
        active: true,
      },

      changePassword: false,
      newPassword: '',
      showPassword: false,
      newUserStarted: false,
      accessModel: [],
      userProviders: [],
      onEdit: false,
      events: [],
      eventSelected: null,
      analytics: {
        eventsGoOnSelectedMonthByDay: {},
        eventsSharedOnSelectedMonthByDay: {},
        eventsDisclosureOnSelectedMonthByDay: {},
        eventsLinksOnSelectedMonthByDay: {},
      },
      options: {
        responsive: true,
        scales: {
          yAxes: {
            // min: 0,
          }
        }
      },
      dateMonth: moment(),
      lastEventId: null,
      lastMonthKey: null,
      reportInfo: null,
    }
  },
  watch: {
    dateMonth(value) {
      this.loadEventAnalytics();
    }
  },
  computed:{
    ...authComputed,
    haveFacebook: {
      get() {
        if (
            this.userProviders.find(function (el) {
              return el.providerId === 'facebook.com'
            })
        ) {
          return true
        }

        return false
      },
    },
    haveGoogle: {
      get() {
        return !!this.userProviders.find(function (el) {
          return el.providerId === 'google.com'
        })
      },
    },
    haveApple: {
      get() {
        return !!this.userProviders.find(function (el) {
          return el.providerId === 'apple.com'
        })
      },
    },
    havePassword: {
      get() {
        return !!this.userProviders.find(function (el) {
              return el.providerId === 'password'
            });
      },
    },
    isItSelf() {
      return this.currentUser?.id===this.form.id;
    },
    eventsGoByMonthSelectedGenres() {
      const data = this.analytics.eventsGoOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataGenres(data, keys);
    },
    eventsGoByMonthSelectedAges() {
      const data = this.analytics.eventsGoOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataAges(data, keys);
    },

    eventsSharedByMonthSelectedGenres() {
      const data = this.analytics.eventsSharedOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataGenres(data, keys);
    },
    eventsSharedByMonthSelectedAges() {
      const data = this.analytics.eventsSharedOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataAges(data, keys);
    },
    eventsDisclosureByMonthSelectedGenres() {
      const data = this.analytics.eventsDisclosureOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataGenres(data, keys);
    },
    eventsDisclosureByMonthSelectedAges() {
      const data = this.analytics.eventsDisclosureOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataAges(data, keys);
    },

    eventsLinksByMonthSelectedGenres() {
      const data = this.analytics.eventsLinksOnSelectedMonthByDay;
      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataGenres(data, keys);
    },
    eventsLinksByMonthSelectedAges() {
      const data = this.analytics.eventsLinksOnSelectedMonthByDay;
      console.log('data', data);

      const keys = [];
      let maxDay = parseInt(this.dateMonth.endOf('month').format('DD'));
      for (let i=1;i<=maxDay;i++) {
        keys.push({
          value: `${this.monthKey}-${i.toString().padStart(2, '00')}`,
          text: i.toString().padStart(2, '00'),
        });
      }
      return getDataAges(data, keys);
    },
  },
  updated() {
    /*
    for (const error in this.error || {}){
      if (this.form[error] && this.error[error]){ this.error[error] = false }
    } */
   // this.$refs.form.resetValidation()
  },
  async beforeMount() {
  },
  methods: {
    async beforeInit() {
      this.changePassword= false;
      this.newPassword= '';
      this.showPassword= false;
      this.newUserStarted= false;
      this.accessModel = [];
      this.onEdit = false;
      this.eventSelected = null;
      this.lastEventId = null;
      this.lastMonthKey = null;
      this.loadEventAnalytics();
    },
    async checkUserExist(result) {
      if(result.data.providerCode==='auth/user-not-found') {
        this.form.errorUserAuthNotFound = true;
        await firestore.collection(this.resource).doc(this.valueId).set({errorUserAuthNotFound: true},{merge: true});
      }
    },
    async beforeSaveAccount(form) {
      //  let fakeMail = 'ua_'+form.email.replace('@', '_')+'@adminusers.'+appConfig.emailKey;
      let fakeMail = form.email;
      let result = null;
      if(this.isNew) {
        if(!fakeMail) {
          this.$showError({title: this.$t('form.error.emailRequired')})
          return false;
        }
        if(!this.newPassword) {
          this.$showError({title: this.$t('form.error.passwordRequired')})
          return false;
        }
        result = await functions.httpsCallable('auth-createUser')({id: this.valueId, workspace:'CLIENT', email: fakeMail, password: this.newPassword, name: form.name || ''});
        if(result.data.errorCode!==status.apiErrorCode.OK) {
          console.log('Error', result.data.errorCode, result.data.providerCode);
        //  if(result.data.providerCode==='auth/credential-already-in-use' || result.data.providerCode==='auth/email-already-exists') {}
          throw this.JwGetError(this.$t('form.error.createUser')+': '+ (result.data.providerCode?this.$t('login.providerCode.'+result.data.providerCode):this.$t('apiErrorCode.'+result.data.errorCode)));
        }
        return true;
      }
      else {
        if(this.changePassword && this.isSuperAdmin) {
          if(!this.newPassword) {
            this.$showError({title: this.$t('form.error.passwordRequired')})
            return false;
          }
          result = await functions.httpsCallable('auth-changePassword')({id: this.valueId, workspace:'CLIENT', password: this.newPassword});
          if(result.data.errorCode!==status.apiErrorCode.OK) {
            await this.checkUserExist(result);
            console.log('Error', result.data.errorCode, result.data.providerCode);
            throw this.JwGetError(this.$t('form.error.changePassword')+': '+ (result.data.providerCode?this.$t('login.providerCode.'+result.data.providerCode):this.$t('apiErrorCode.'+result.data.errorCode)));
          }
        }
        if(fakeMail && this.original.email!==fakeMail && this.isSuperAdmin) {
          if(!fakeMail) {
            this.$showError({title: this.$t('form.error.emailRequired')})
            return false;
          }
          result = await functions.httpsCallable('auth-changeEmail')({id: this.valueId, workspace:'CLIENT', email: fakeMail});
          if(result.data.errorCode!==status.apiErrorCode.OK) {
            console.log('Error', result.data.errorCode, result.data.providerCode);
            await this.checkUserExist(result);
            throw this.JwGetError(this.$t('form.error.changeEmail')+': '+ (result.data.providerCode?this.$t('login.providerCode.'+result.data.providerCode):this.$t('apiErrorCode.'+result.data.errorCode)));
          }
          //form.fakeMail = fakeMail;
        }
        return true;
      }
    },
    async beforeSave(form) {
      if (this.$refs.form && !this.$refs.form.validate()) {
        console.log('form inválido nos requires')
        this.tryingToUpdate = false;
        return;
      }
      if(typeof form.birthDateISO==='string') {
        let date = moment(form.birthDateISO);
        if(date.isValid()) {
          date.hour(12).minute(0);
          form.birthDate = Timestamp.fromDate(date.toDate());
        }
        else {
          form.birthDateISO = null;
        }
      }
      else {
        form.birthDate = null;
      }
      form.phone = form.phone.replace('+55', '');
      form = {
        ...form,
        active: Boolean(form.active),
        nameLowerCase: StringUtils.deburr(form.name),
        phone: '+55'+form.phone.replace(/[^\d]/g, ''),
        cpf: (form.cpf || '').replace(/[^\d]/g, ''),
        email: (form.email||'').toLowerCase().trim(),
      }
      delete form.search;

      let result = await this.beforeSaveAccount(form);
      if(result) {
        return form;
      }
      else {
        console.log('Skipped');
        await this.close();
        return false;
      }
    },
    async beforeLoad(form) {
      this.reportInfo = null;
      form.phone = (form.phone||'').replace('+55', '');

      console.log('firebase.auth().currentUser?.id', firebase.auth().currentUser?.uid);
      console.log('form.id', form.id);

      if (this.currentUser?.id===form.id) {
        console.log('equals');
        this.userProviders = firebase.auth().currentUser.providerData;
      }
      else {
        console.log('Diff');
      }
      if(!form.birthDateISO && form.birthDate) {
        let m = moment(form.birthDate.toDate());
        if(m.isValid()) {
          form.birthDateISO = m.format('YYYY-MM-DD');
        }
      }
      this.$bind('events', firestore.collection('events').where('ownerId', '==', this.valueId)
          .orderBy('eventDate', 'desc'));


      if (this.reportId) {
        const reportInfo = {
          report: {...(await firestore.collection('reportsUsers').doc(this.reportId).get()).data(), id: this.reportId},
        };
        if (reportInfo.report?.reportById) {
          reportInfo.userBy = {...(await firestore.collection('users').doc(reportInfo.report.reportById).get()).data(), id: reportInfo.report.reportById};
        }

        this.reportInfo = reportInfo;
      }

      return form;
    },
    async removeProvider(provider) {
      if (provider.providerId === 'password') {
        this.$showError({ title: this.$t('form.error.deleteProviderEmail') })
        return
      }

      if (!this.havePassword) {
        this.$showError({
          title: this.$t('form.error.deleteProviderEmailNeed'),
        })
        return
      }

      try {
        await firebase.auth().currentUser.unlink(provider.providerId)
        this.$showSuccess()
      } catch (error) {
        this.$showError({ title: this.$t('form.error.deleteProviderEmail') })
      }
      if (firebase.auth().currentUser) {
        this.userProviders = firebase.auth().currentUser.providerData
      }
    },

    async addProviderSocial(provider) {
      let providerObj = null
      switch (provider) {
        case 'facebook.com':
          providerObj = new firebase.auth.FacebookAuthProvider()
          break
        case 'google.com':
          providerObj = new firebase.auth.GoogleAuthProvider()
          break
        case 'apple.com':
          providerObj = new firebase.auth.OAuthProvider('apple.com')
          break
        default:
          return Promise.reject(new Error('Unknown provider'))
      }

      try {
        await firebase.auth().currentUser.linkWithPopup(providerObj);
        this.$showSuccess();
      } catch (error) {
        this.$showError({
          title: this.$t('login.error.loginTitle'),
          text: this.$t('login.providerCode.' + error.code),
        })
      }
      if (firebase.auth().currentUser) {
        this.userProviders = firebase.auth().currentUser.providerData
      }
    },
    async showEvent(event) {
      this.eventSelected = event;
      await this.$nextTick();
      document.getElementById('eventDetais').scrollIntoView({
        behavior: 'smooth'
      });
      console.log(this.eventSelected?.id);
      this.loadEventAnalytics();
    },
    getLocalEvent(event) {
      let local = event.eventPlace?.country?event.eventPlace.country+' | ':'';
      local += event.eventPlace?.city?event.eventPlace.city+' ':'';
      local += event.eventPlace?.state?'('+event.eventPlace.state+')':'';
      return local;
    },
    async loadEventAnalytics() {
      this.analytics.eventsGoOnSelectedMonthByDay = null;
      this.analytics.eventsSharedOnSelectedMonthByDay = null;
      this.analytics.eventsDisclosureOnSelectedMonthByDay = null;
      this.analytics.eventsLinksOnSelectedMonthByDay = null;
      if (!this.eventSelected) {
        this.lastEventId = null;
        return;
      }
      this.monthKey = this.dateMonth.format('YYYY-MM');
      if (this.lastMonthKey===this.monthKey && this.lastEventId===this.eventSelected?.id) {
        return;
      }
      this.lastMonthKey = this.monthKey;
      this.lastEventId = this.eventSelected?.id;
      const startMonthDay = this.dateMonth.startOf('month').format('YYYY-MM-DD');
      const endMonthDay = this.dateMonth.clone().add(+1, 'month').startOf('month').format('YYYY-MM-DD');

      const eventsGoOnSelectedMonthByDayP = firebase.database()
          .ref(`/Analytics/EventsGo/Daily/${this.lastEventId}`)
          .orderByKey()
          .startAt(startMonthDay)
          .endAt(endMonthDay)
          .get();
      const eventsSharedOnSelectedMonthByDayP = firebase.database()
          .ref(`/Analytics/EventsShared/Daily/${this.lastEventId}`)
          .orderByKey()
          .startAt(startMonthDay)
          .endAt(endMonthDay)
          .get();
      const eventsDisclosureOnSelectedMonthByDayP = firebase.database()
          .ref(`/Analytics/EventsDisclosure/Daily/${this.lastEventId}`)
          .orderByKey()
          .startAt(startMonthDay)
          .endAt(endMonthDay)
          .get();

      const eventsLinksOnSelectedMonthByDayP = [];
      for (const link of this.eventSelected?.lstLinks || []) {
        eventsLinksOnSelectedMonthByDayP.push(firebase.database()
            .ref(`/Analytics/EventsLinkVisited/Daily/${this.lastEventId}/${link.id}`)
            .orderByKey()
            .startAt(startMonthDay)
            .endAt(endMonthDay)
            .get())
      }

      this.analytics.eventsGoOnSelectedMonthByDay = (await eventsGoOnSelectedMonthByDayP).val();
      this.analytics.eventsSharedOnSelectedMonthByDay = (await eventsSharedOnSelectedMonthByDayP).val();
      this.analytics.eventsDisclosureOnSelectedMonthByDay = (await eventsDisclosureOnSelectedMonthByDayP).val();

      const tmp = (await Promise.all(eventsLinksOnSelectedMonthByDayP)).map(a=>a.val());
      this.analytics.eventsLinksOnSelectedMonthByDay = ObjectUtils.mergeSumObj(tmp);
    },
    async deleteUser() {
      if(!await this.$showConfirm({title: this.$t('form.alert.deleteUser')})) {
        return;
      }
      await firestore.collection(this.resource).doc(this.valueId).set({
        active: false
      }, {merge: true});
      this.$showSuccess();
    },
    async reportAccepted() {
      if (!this.reportId) {
        this.$showError();
        return;
      }
      await firestore.collection('reportsUsers').doc(this.reportId).set({
        status: ReportStatus.ACCEPTED,
        acceptedBy: this.currentUser.id,
        acceptedAt: FieldValue.serverTimestamp(),
        meta: {
          updatedAt: FieldValue.serverTimestamp(),
          updatedBy: this.currentUser.id,
        }
      }, {merge: true});
      this.reportInfo.report.status = ReportStatus.ACCEPTED;
      this.$showSuccess();
    },
    async reportRejected() {
      if (!this.reportId) {
        this.$showError();
        return;
      }
      await firestore.collection('reportsUsers').doc(this.reportId).set({
        status: ReportStatus.REJECTED,
        rejectedBy: this.currentUser.id,
        rejectedAt: FieldValue.serverTimestamp(),
        meta: {
          updatedAt: FieldValue.serverTimestamp(),
          updatedBy: this.currentUser.id,
        }
      }, {merge: true});
      this.reportInfo.report.status = ReportStatus.REJECTED;
      this.$showSuccess();
    },
    sendNotification() {
      const b64 = encode(JSON.stringify({
        refId: this.valueId,
        type: status.NotificationManualType.USER,
        title: this.form.name || '',
        image: {
          url: this.form.profilePictureUrl || null,
          mime: 'image/jpg'
        },
      }));
      this.$router.push({name: 'admin/notifications', params: {id: this.$newId()}, query: {info: b64}});
    },
    async setPassword() {
      const newPassword = await this.$showPrompt({input: 'password', title: 'Nova senha'});
      if (!newPassword) {
        return;
      }
      const result = await functions.httpsCallable('auth-changePassword')({id: this.valueId, workspace:'CLIENT', password: newPassword});
      if(result.data.errorCode!==status.apiErrorCode.OK) {
        console.log('Error', result.data.errorCode, result.data.providerCode, result.data);
        this.$showError({
          title: 'Erro ao definir senha',
          text: (result.data.providerCode?this.$t('login.providerCode.'+result.data.providerCode):this.$t('apiErrorCode.'+result.data.errorCode)),
        });
      }
      else {
        this.$showSuccess();
      }
    },
  }
}
</script>

<template>
  <div>
    <div v-show="isLoading" >
      <VProgressCircular indeterminate color="primary"></VProgressCircular>
    </div>
    <div v-if="!isLoading">
      <div class="reportBy" v-if="reportInfo">
        Reportado por: {{reportInfo.userBy?.name}} ({{reportInfo.userBy?.username}}) <br>
        Motivo: {{reportInfo.report?.reason}} <br>
        Status: {{$t('ReportStatus.'+reportInfo.report?.status)}} <br>
        Reportado: {{moment(reportInfo.meta?.createdAt?.toDate()).format('DD/MM/YYYY HH:mm')}} <br>
        <div v-if="reportInfo.report?.status===ReportStatus.REPORTED">
          <VBtn
              max-width="300px"
              color="success"
              class="mr-4 mt-3"
              @click="reportAccepted"
          >
            Marcar como aceito
          </VBtn>
          <VBtn
              max-width="300px"
              color="success"
              class="mr-4 mt-3"
              @click="reportRejected"
          >
            Marcar como recusado
          </VBtn>
        </div>

        <hr class="mt-3" />
      </div>
      <div v-if="onEdit">
        <VForm ref="form">
          <VContainer>
            <VRow>
              <VCol cols="12" xs="6" sm="6" md="6">
                <VTextField
                    v-model="form.email"
                    :rules="[rules.email, rules.required]"
                    :label="$t('form.email')"
                ></VTextField>
              </VCol>
              <VCol cols="12" xs="6" sm="6" md="6">
                <VTextField
                    v-model="form.username"
                    :rules="[rules.required]"
                    :label="$t('form.username')"
                ></VTextField>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" sm="12">
                <VTextField
                    v-model="form.name"
                    :rules="[rules.required]"
                    :label="$t('form.name')"
                ></VTextField>
              </VCol>
              <VCol cols="6" sm="6" md="6">
                <VTextField
                    v-model="form.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :label="$t('form.phone')"
                ></VTextField>
              </VCol>
              <VCol cols="12" sm="6" md="6">
                <FcmDate
                    v-model="form.birthDateISO"
                    :label="$t('form.birthDate')"
                    :read-only="false"
                    type="iso"
                ></FcmDate>
              </VCol>
              <VCol cols="12" sm="4" md="4">
                <VSelect
                    v-model="form.gender"
                    :items="[
                          {text: $t('form.gender.male'), value: 'M'},
                          {text: $t('form.gender.female'), value: 'F'},
                          {text: $t('form.gender.other'), value: 'O'},
                        ]"
                    solo
                    :placeholder="$t('form.tellTheSex')"
                ></VSelect>
              </VCol>
            </VRow>
            <div v-if="isItSelf">
              <div>{{$t('login.providers')}}</div>
              <table class="tableAll">
                <tr v-for="item of userProviders">
                  <td>{{ item.providerId || '' }}</td>
                  <td>{{ item.email || '' }}</td>
                  <td>{{ item.uid || '' }}</td>
                  <td><VBtn @click="removeProvider(item)"><VIcon>mdi-delete</VIcon></VBtn></td>
                </tr>
              </table>
              <br>
              <VBtn
                  v-if="!haveFacebook"
                  class="mr-2"
                  :disabled="tryingToUpdate"
                  @click="addProviderSocial('facebook.com')"
              >
                <VIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addFacebook')}}
                </span>
              </VBtn>
              <br v-if="!haveGoogle" />
              <VBtn
                  v-if="!haveGoogle"
                  class="mr-2"
                  :disabled="tryingToUpdate"
                  @click="addProviderSocial('google.com')"
              >
                <VIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addGoogle')}}
                </span>
              </VBtn>
              <br v-if="!haveGoogle" />
              <VBtn
                  v-if="!haveApple"
                  class="mr-2"
                  :disabled="tryingToUpdate"
                  @click="addProviderSocial('apple.com')"
              >
                <VIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addApple')}}
                </span>
              </VBtn>
            </div>

            <VRow
                v-if="((isNew && newUserStarted) || (!isNew && isSuperAdmin) || (!isNew && currentUser.id === valueId))"
            >
              <VCol v-if="!isNew && (isSuperAdmin || currentUser.id === valueId)" cols="12" sm="2" md="2">
                <VSwitch
                    v-model="changePassword"
                    :label="$t('form.changePassword')"
                    :value="true"
                    hide-details
                    inset
                    dense
                ></VSwitch>
              </VCol>
              <VCol v-show="changePassword || isNew" cols="12" sm="6" md="5">
                <VTextField
                    v-model="newPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :label="$t('form.password')"
                    autocomplete="no"
                    :rules="changePassword || isNew?[rules.required]:[]"
                    @click:append="showPassword = !showPassword"
                ></VTextField>
              </VCol>
            </VRow>
            <VRow v-if="hasError">
              <VCol cols="12" sm="12" md="12">
                <p>
                  {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
                </p>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" sm="12" md="12">
                <VProgressLinear v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :value="uploadProgress"></VProgressLinear>
              </VCol>
            </VRow>

            <VRow class="'mt-4'">
              <VCol>
                <VBtn
                    v-if="!isNew || newUserStarted"
                    max-width="300px"
                    :disabled="!valid || tryingToUpdate"
                    color="success"
                    class="mr-4 mt-3"
                    @click="conclude"
                >
                  {{$t('form.conclude')}}
                </VBtn>
                <VBtn
                    v-if="(!isNew || newUserStarted)"
                    :disabled="tryingToUpdate"
                    color="warning"
                    class="mt-3"
                    @click="close(null, true)"
                >
                  {{$t('form.close')}}
                </VBtn>
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
      </div>
      <div v-else>
        <div class="cover" v-if="form.coverPictureUrl">
          <img :src="form.coverPictureUrl" />
        </div>
        <div class="headerShow">
          <div class="profile" v-if="form.hdProfilePictureUrl">
            <img :src="form.hdProfilePictureUrl" />
          </div>
          <div class="profileBody">
            <h3>{{form.name}} ({{form.username}})</h3>
            <h3>{{form.email}} {{StringUtils.formatPhone(form.phone)}}</h3>
            <h3 v-if="form.birthDateISO">Nascimento {{moment(form.birthDateISO).format('DD/MM/YYYY')}}</h3>
            <h3 v-if="form.meta?.createdAt?.toDate">Criado em {{moment(form.meta.createdAt.toDate()).format('DD/MM/YYYY HH:mm')}}</h3>
          </div>
          <br>
          <div>
            <VBtn
                v-if="form.active"
                max-width="500px"
                color="success"
                class="mr-4 mt-3"
                @click="sendNotification"
            >
              Enviar notificação vinculando esse usuário
            </VBtn>
            <VBtn
                v-if="form.active"
                max-width="300px"
                color="success"
                class="mr-4 mt-3"
                @click="deleteUser"
            >
              Deletar usuário
            </VBtn>
            <VBtn
                max-width="300px"
                color="success"
                class="mr-4 mt-3"
                @click="onEdit = true"
            >
              Editar
            </VBtn>
            <VBtn
                max-width="300px"
                color="success"
                class="mr-4 mt-3"
                @click="setPassword"
            >
              Definir nova senha
            </VBtn>
          </div>
        </div>
        <hr>
        <div class="events">
          <div v-for="ev in events" :key="ev.id" class="container" @click="showEvent(ev)">
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-1">
                <header class="voxtom-splash-logo preview-voxtom-splash-logo">
                  <div class="menu_user">
                    <div class="imagem_capa">
                      <img :src="ev.eventImageUrl">
                    </div>
                    <div class="user_info_studio">
                      <div class="artist_attr_studio">
                        <div class="local">{{getLocalEvent(ev)}} {{ev.eventDate?.toDate?moment(ev.eventDate?.toDate()).format('DD/MM/YYYY'):''}}</div>
                        <div class="name">
                          {{ev.title}}
                        </div>
                        <div class="sobremim">{{ev.description}}</div>
                      </div>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div id="eventDetais" v-if="eventSelected" >
          <h3 class="mb-4">Detalhes do evento</h3>
          <div class="eventSelectedHeader">
            <div class="containerEvent row">
              <div class="col-12 col-lg-10 offset-lg-1">
                <header class="voxtom-splash-logo preview-voxtom-splash-logo">
                  <div class="menu_user">
                    <div class="imagem_capa">
                      <img :src="eventSelected.eventImageUrl">
                    </div>
                    <div class="user_info_studio">
                      <div class="artist_attr_studio">
                        <div class="local">{{getLocalEvent(eventSelected)}} {{eventSelected.eventDate?.toDate?moment(eventSelected.eventDate?.toDate()).format('DD/MM/YYYY'):''}}</div>
                        <div class="name">
                          {{eventSelected.title}}
                        </div>
                        <div class="sobremim">{{eventSelected.description}}</div>
                      </div>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-13">
              <h2>Estatísticas do evento no mês selecionado</h2>
              <div>
                <div class="dateMonth">
                  <FcmDate
                      v-model="dateMonth"
                      type="moment"
                      pick-month
                      start-on-month
                      format="MM/YYYY"
                      mask="##/####"
                  />
                </div>
              </div>
            </div>
            <div class="charts">
              <h3>
                Participação de usuários em eventos
              </h3>
              <CLine v-if="eventsGoByMonthSelectedGenres" class="chart" :data="eventsGoByMonthSelectedGenres" :options="options" />
              <CLine v-if="eventsGoByMonthSelectedAges" class="chart" :data="eventsGoByMonthSelectedAges" :options="options" />
            </div>
            <div class="charts">
              <h3>
                Divulgações de eventos
              </h3>
              <CLine v-if="eventsDisclosureByMonthSelectedGenres" class="chart" :data="eventsDisclosureByMonthSelectedGenres" :options="options" />
              <CLine v-if="eventsDisclosureByMonthSelectedAges" class="chart" :data="eventsDisclosureByMonthSelectedAges" :options="options" />
            </div>
            <div class="charts">
              <h3>
                Compartilhamentos de eventos
              </h3>
              <CLine v-if="eventsSharedByMonthSelectedGenres" class="chart" :data="eventsSharedByMonthSelectedGenres" :options="options" />
              <CLine v-if="eventsSharedByMonthSelectedAges" class="chart" :data="eventsSharedByMonthSelectedAges" :options="options" />
            </div>
            <div class="charts">
              <h3>
                Cliques em links de eventos
              </h3>
              <CLine v-if="eventsLinksByMonthSelectedGenres" class="chart" :data="eventsLinksByMonthSelectedGenres" :options="options" />
              <CLine v-if="eventsLinksByMonthSelectedAges" class="chart" :data="eventsLinksByMonthSelectedAges" :options="options" />
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// @import '@design';

.dateMonth {
  max-width: 180px;
}

.reportBy {
  margin-bottom: 20px;
  padding-bottom: 0px;
}

.charts {
  .chart {
    // background-color: #1E1E1E;
    max-height: 550px;
  }
}
.eventSelectedHeader {
  display: flex;
  justify-content: center;
}

.events {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
}

.cover img {
  max-height: 300px;
  max-width: 100%;
}

.headerShow {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  flex-wrap: wrap;

  .profile img {
    max-height: 130px;
  }

  .profileBody {
    flex: 2;
    padding-left: 10px;
  }
  padding-bottom: 20px;
}

.tableAll {
  border-collapse: collapse;
  border: 1px solid var(--v-listItemBorder-base);
  th, td {
    border: 1px solid var(--v-listItemBorder-base);
    padding: 10px 25px 10px 10px;
  }
}



.preview-logo {
    max-height: 45px;
}

.oficial_logo {
    margin-right: 15px;
}


.containerEvent {
    cursor: pointer;
    max-width: 400px;
}

.audience_content {
    margin-top: 8px;
    display: flex;
    align-content: space-around;
    font-family: Roboto;
}
    .audience_box {
        flex: 1;
        margin: 10px;
        display: flex;
        padding: 10px;
        background-color: #1C1C1C;
        align-content: space-around;
        min-width: 100px;
        border-radius: 9px;
    }
        .audience_icon {
            width: 85px;
            height: 85px;
        }
        .audience_data {
            flex: 1;
            display: flex;
            align-content: space-around;
            flex-direction: column;
        }
            .audience_number {
                flex: 1;
                font-size: 28px;
                color: #B1B1B1;
                font-weight: 600;
            }
            .audience_text {
                flex: 1;
                font-size: 15px;
                color: #23FF00;
                font-weight: 600;
            }
.info_content {
    margin-top: 5px;
    font-family: Roboto;

}
    .info_item {
        display: flex;
        padding: 10px;
        border-bottom: solid 1px #404040;
    }
        .info_icon {
            width: 48px;
            height: 48px;
        }
        .info_text {
            flex: 1;
            align-self: center;
            /* color: #f2f2f2; */
            color: #ffffff;
            font-size: 17px;
            text-align: justify;
            padding-left: 25px;
        }
        .info_icon_right {
            width: 16px;
            height: 26px;
            align-self: center;
        }

.download_content {
    margin-top: 15px;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 8px;
    padding-right: 20px;
}
    .download_chamada {
        flex: 1;
        font-family: Roboto;
        font-size: 32px;
        color: #0e0e0e;
        align-self: center;
    }
    .download_logo {
        /*width: 48px;*/
        height: 41px;
        margin-right: 17px;
        align-content: space-around;
        align-self: center;
    }

    .download_logo img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }

    .download_android {

    }
    .download_ios {

    }

.imagem_capa {
    img {
      min-height: 200px;
      max-height: 550px;
      max-width: 400px;
    }
}


</style>


















